<template>
  <div class="container">
    <my-nav></my-nav>
    <div class="others">
      <!-- <div class="zb others-item" @click="zbQrChange(true)">
        <van-image :src="GameLogo"></van-image>
        <span class="zb-tag">直播中</span>
      </div> -->
      <div class="kefu others-item" @click="kefuStatus = true">客服</div>
      <div
        class="订单 others-item"
        @click="$router.push({ name: 'OrderList' })"
      >
        订单
      </div>
      <!-- <div class="tuiguang others-item" @click="tgActionSheet = true">推广</div> -->
    </div>

    <van-popup v-model="kefuStatus" round closeable>
      <van-image :src="kefuQrUrl"></van-image>
      <div
        style="text-align:center;padding: 0 0 10px 0;font-size: 12px"
        @click="copyWechatId"
      >
        <p>
          客服微信：<span style="color: #f00">{{ wechatId }}</span>
        </p>
        <p style="color: #666; ">
          点击复制微信号
        </p>
      </div>
    </van-popup>

    <van-popup :value="zbQrStatus" @input="zbQrChange" round closeable>
      <van-image :src="zbImageUrl"></van-image>
      <div style="text-align:center;padding: 0 0 10px 0;font-size: 12px">
        <span>长按识别进入直播间</span>
      </div>
    </van-popup>

    <van-action-sheet
      v-model="tgActionSheet"
      @select="onTgActionSelect"
      :actions="actions"
    ></van-action-sheet>

    <van-dialog
      v-model="accountInputDialog"
      title="复制推广链接"
      :show-confirm-button="false"
    >
      <van-field
        v-model="account"
        placeholder="请输入你的账号"
        clearable
      ></van-field>
      <van-button block @click="copyClipboardFun">确认复制</van-button>
      <span @click="copy" ref="copy"></span>
    </van-dialog>

    <router-view></router-view>
  </div>
</template>
<script>
import { Image, Popup, ActionSheet, Field, Button, Notify, Toast } from "vant";
import LeftImage from "@/assets/images/bg_left.png";
import RightImage from "@/assets/images/bg_right.png";
import GameLogo from "@/assets/images/logo.png";
import { tgUrl } from "@/config/settings";
import handleClipboard from "@/utils/clipboard";
import { getShortUrl } from "@/api/common";
import Nav from "@/components/Nav";
import { mapState, mapMutations } from "vuex";

export default {
  name: "Tlbb",
  components: {
    "van-image": Image,
    "van-popup": Popup,
    "van-action-sheet": ActionSheet,
    "van-field": Field,
    "van-button": Button,
    "my-nav": Nav
  },
  data() {
    return {
      LeftImage,
      RightImage,
      GameLogo,
      kefuStatus: false,
      tgActionSheet: false,
      actions: [
        {
          type: "cope",
          name: "复制推广地址"
        },
        {
          type: "show",
          name: "推广奖励说明"
        },
        {
          type: "get",
          name: "领取推广奖励"
        }
      ],
      accountInputDialog: false,
      account: "",
      copeContent: ""
    };
  },
  computed: {
    ...mapState({
      zbQrStatus: state => state.zb.zbStatus,
      wechatId: state => state.setting.agentSetting.wechatId,
      kefuQrUrl: state => state.setting.agentSetting.kefuQrUrl,
      zbImageUrl: state => state.setting.agentSetting.zbImageUrl
    })
  },
  mounted() {
    if (this.$route.query.u) {
      window.localStorage.setItem("parent_account", this.$route.query.u);
    }
  },
  methods: {
    ...mapMutations({
      changeZbQrStatus: "zb/SetZbStatus"
    }),
    handleClipboard,
    getShortUrl,
    onTgActionSelect(item) {
      this.tgActionSheet = false;
      switch (item.type) {
        case "cope":
          // eslint-disable-next-line
          let account = window.localStorage.getItem("account");
          if (account) {
            this.account = account;
          }
          this.accountInputDialog = true;
          break;
        case "show":
          this.$router.push({ name: "InvitationDesc" });
          break;
        case "get":
          window.location.href = tgUrl;
          break;
      }
    },
    async copyClipboardFun() {
      let loading = Toast.loading({
        message: "加载中...",
        forbidClick: true,
        duration: 0
      });
      try {
        let domain = window.location.host;
        let protocol = window.location.protocol + "//";
        let url = protocol + domain + "/tlbb/register?u=" + this.account;
        let { data } = await getShortUrl({ url });
        // url = protocol + domain + '/s/' + data.char

        url = window.location.origin + "/s/" + data.char;
        this.copeContent = `注册地址：${url}`;
        let that = this;
        setTimeout(() => {
          that.$refs.copy.click();
          loading.clear();
        }, 10);
      } catch (err) {
        Notify({ type: "danger", message: err.message });
        loading.clear();
      }
    },
    copy(event) {
      event.preventDefault();
      this.handleClipboard(this.copeContent, event);
      window.localStorage.setItem("account", this.account);
      this.accountInputDialog = false;
    },
    copyWechatId(event) {
      event.preventDefault();
      this.handleClipboard(this.wechatId, event);
    },
    zbQrChange() {
      window.location.href = this.zbImageUrl;
      // this.changeZbQrStatus(status)
    }
  }
};
</script>
<style lang="less" scoped>
.container {
  background-color: #f5f5f5;
  min-height: 100vh;
}

.others {
  position: fixed;
  right: 20px;
  bottom: 200px;
  z-index: 1000;

  .others-item {
    width: 40px;
    height: 40px;
    line-height: 40px;
    background-color: rgba(255, 0, 0, 0.6);
    border-radius: 50px;
    color: #fff;
    text-align: center;
    font-size: 12px;
    box-shadow: 0 0 5px #333;
    margin-bottom: 10px;
  }

  .zb {
    // opacity: 0.8;
    overflow: hidden;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.3);
      z-index: 1;
      left: 0;
      top: 0;
    }
    .zb-tag {
      position: absolute;
      z-index: 2;
      left: 50%;
      top: 50%;
      line-height: 12px;
      display: inline-block;
      background-color: #f00;
      font-size: 10px;
      padding: 1px 2px;
      border-radius: 8px;
      transform: translate(-50%, -50%);
      width: 100%;
    }
  }
}
</style>
