import {
  getLyPayParams,
  getQyPayParams,
  getQtPayParams,
  getYzfPayParams
} from "@/api/pay";

const state = () => {
  return {};
};

const mutations = {};

const actions = {
  // eslint-disable-next-line
  getLyPayParams({}, data) {
    return getLyPayParams(data);
  },
  // eslint-disable-next-line
  getQyPayParams({}, data) {
    return getQyPayParams(data);
  },
  // eslint-disable-next-line
  getQtPayParams({}, data) {
    return getQtPayParams(data);
  },
  // eslint-disable-next-line
  getYzfPayParams({}, data) {
    return getYzfPayParams(data);
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
