<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "App",
  components: {},
  mounted() {
    this.getSetting().catch(err => {
      console.log(err);
    });

    this.getAgentSetting(this.getQueryParam("a")).catch(err => {
      console.log(err);
    });
  },
  methods: {
    ...mapActions("setting", ["getSetting", "getAgentSetting"]),
    getQueryParam(paramName) {
      var searchString = window.location.search.substring(1); // 获取查询字符串并去掉开头的问号
      var a = searchString.split("&"); // 将查询字符串分割成多个参数
      for (var i = 0; i < a.length; i++) {
        var b = a[i].split("="); // 分割成键和值
        if (b[0] === paramName) {
          return decodeURIComponent(b[1]); // 返回找到的值，并解码URL编码
        }
      }
      return null; // 如果没有找到则返回null
    }
  }
};
</script>
