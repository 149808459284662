import request from "@/utils/request";

export function getSetting() {
  return request({
    url: "/api/tlbb/setting/main",
    method: "get"
  });
}

export function getAgentSetting(agentId) {
  return request({
    url: "/api/tlbb/setting/agent?a=" + agentId,
    method: "get"
  });
}
