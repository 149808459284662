import request from "@/utils/request";

export function getLyPayParams(data) {
  return request({
    url: "/api/pay/ly/params",
    method: "post",
    data
  });
}

export function getQyPayParams(data) {
  return request({
    url: "/api/pay/qy/params",
    method: "post",
    data
  });
}

export function getQtPayParams(data) {
  return request({
    url: "/api/pay/qt/params",
    method: "post",
    data
  });
}

export function getYzfPayParams(data) {
  return request({
    url: "/api/pay/yzf/params",
    method: "post",
    data
  });
}
