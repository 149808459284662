import { getSetting, getAgentSetting } from "@/api/setting";

const state = () => {
  return {
    setting: {
      downloadUrl: "",
      downloadBySelf: false,
      wechatId: "",
      qqNo: "",
      kefuQrUrl: "",
      groupQrUrl: "",
      zbImageUrl: "",
      popContent: "",
      client3BaseUrl: ""
    },
    agentSetting: {
      wechatId: "",
      qqNo: "",
      kefuQrUrl: "",
      groupQrUrl: ""
    }
  };
};

const mutations = {
  CHANGE_SETTING(state, setting) {
    state.setting = setting;
  },
  CHANGE_AGENT_SETTING(state, setting) {
    state.agentSetting = setting;
  }
};

const actions = {
  // eslint-disable-next-line
  async getSetting({ commit }) {
    try {
      let { data } = await getSetting();
      commit("CHANGE_SETTING", data.data);
    } catch (err) {
      throw new Error(err);
    }
  },
  // eslint-disable-next-line
  async getAgentSetting({ commit }, agentId) {
    try {
      let { data } = await getAgentSetting(agentId);
      console.log(data);
      commit("CHANGE_AGENT_SETTING", data);
    } catch (err) {
      throw new Error(err);
    }
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
