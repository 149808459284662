import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueRouter from "vue-router";
import VueMeta from "vue-meta";
import "@/assets/styles/base.less";
import store from "@/store";
import { Lazyload, Icon, Dialog } from "vant";

Vue.use(VueRouter);
Vue.use(VueMeta);

Vue.use(Icon);
Vue.use(Dialog);
Vue.use(Lazyload);

Vue.config.productionTip = false;

// 在router中配置每个页面的title
router.beforeEach((to, from, next) => {
  // 防止封链接1
  if (!to.query.t) {
    let newQuery = JSON.parse(JSON.stringify(to.query));
    newQuery.t = new Date().getTime();
    if (!to.query.a) newQuery.a = from.query.a;
    router.push({ name: to.name, query: newQuery });
  }

  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
